import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { InfiniteData } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { useIsMounted } from 'hooks/useIsMounted';
import { useSearchFilterQuery } from 'hooks/useSearchFilterQuery';
import { useSearchInfiniteQuery } from 'hooks/useSearchInfiniteQuery';

import { dealOfferCodeAtom } from 'models/DealOfferModel';
import { searchValuesAtom } from 'models/SearchModel';

import { Vehicle } from 'services/SearchApiService';
import { SearchFilters } from 'services/SearchFiltersApiService';

import { useScrollFormView } from './useScrollFormView';
import { useSyncFormValues } from './useSyncFormValues';

export interface SearchResult {
  count: number;
  items: Vehicle[];
}

interface HookResult {
  handleFetchMore(): Promise<void>;
  ref: React.RefObject<HTMLButtonElement>;
  loadMoreButtonDisabled: boolean;
  isSearchError: boolean;
  isSearchLoading: boolean;
  searchResults?: InfiniteData<SearchResult>;
  hasNextPage?: boolean;
  searchFiltersData?: SearchFilters;
  searchResultsCount: number;
  excludedCount: number;
  handleNavigateToPdp(carId: string): void;
  payAllFeesUpfront: boolean;
  areFiltersLoading: boolean;
}

export const useSearchForm = (): HookResult => {
  const {
    control,
    formState: { isValid, isValidating, dirtyFields },
    resetField,
  } = useFormContext<SearchFormValues>();
  const navigate = useNavigate();
  const formValues = useRecoilValue(searchValuesAtom);
  const { calculationOptions } = formValues ?? {};
  const values = useWatch({ control }) as SearchFormValues;
  const {
    calculationOptions: { tradeIn },
  } = values;
  const resetDealCode = useResetRecoilState(dealOfferCodeAtom);
  const [debouncedValues, setDebouncedValues] = useState(values);

  useDebounce(
    () => {
      setDebouncedValues({
        ...values,
        calculationOptions: {
          ...values.calculationOptions,
          zipCode: values.calculationOptions.location?.zipCode,
        },
      });
    },
    500,
    [values, values.calculationOptions.location?.zipCode]
  );

  const queriesEnabled = isValid && !isValidating;
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError: isSearchError,
    isLoadingError: isLoadingSearchError,
    isRefetchError: isSearchRefecthError,
    isLoading: isSearchLoading,
    data: searchResults,
  } = useSearchInfiniteQuery({
    enabled: queriesEnabled,
    values: debouncedValues,
  });
  const { data: searchFiltersData, isFetching: areFiltersLoading } = useSearchFilterQuery({
    enabled: queriesEnabled,
    values: debouncedValues,
  });
  useSyncFormValues({ values });

  const { ref } = useScrollFormView({ fetchNextPage });

  const handleFetchMore = useCallback(async () => {
    await fetchNextPage();
  }, [fetchNextPage]);

  const handleNavigateToPdp = useCallback((carId: string): void => {
    resetDealCode();
    navigate(`./pdp/${carId}`);
  }, []);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && !dirtyFields.calculationOptions?.actualCashValue) {
      resetField('calculationOptions.actualCashValue', {
        defaultValue: tradeIn ?? 0,
      });
    }
  }, [tradeIn]);

  return {
    searchFiltersData,
    handleFetchMore,
    loadMoreButtonDisabled: !hasNextPage || isFetchingNextPage,
    ref,
    isSearchError: isSearchError || isLoadingSearchError || isSearchRefecthError,
    isSearchLoading,
    areFiltersLoading,
    searchResults,
    hasNextPage,
    searchResultsCount: searchResults?.pages[0]?.count ?? 0,
    excludedCount: searchResults?.pages[0]?.excludedCount ?? 0,
    handleNavigateToPdp,
    payAllFeesUpfront:
      calculationOptions?.payAllFeesUpfront ?? values.calculationOptions.payAllFeesUpfront,
  };
};
