import _ from 'lodash';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { createDefaultCustomer } from 'services/CustomerService';
import { CurrentDealership } from 'services/SearchApiService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import { CalculationOptions, SearchFormValues, SearchResult } from './useSearchFormWrapper';

interface Interval {
  from?: number;
  to?: number;
}

interface SearchFilterOptions extends Omit<SearchFormValues, 'featureTypes' | 'customer' | 'skip'> {
  vinOrStockNumber?: string;
  dealer: number[];
  carState: number[];
  make: string[];
  model: string[];
  trim: string[];
  bodyStyle: string[];
  interiorColor: number[];
  exteriorColor: number[];
  year: Interval;
  price: Interval;
  mileage: Interval;
  monthlyPayment: Interval;
  features: number[];
  fuelType: string[];
}

interface SearchOptions extends SearchFilterOptions {
  maxCount?: number;
}

export interface SearchOptionsPayload extends SearchOptions {
  skip?: number;
}

export function getSearchPayload(input: SearchFormValues & { maxCount: number }): SearchOptions {
  const { maxCount } = input;

  return {
    ...getSearchFiltersPayload(input),
    maxCount,
  };
}

interface DefaultFormOptions {
  currentDealership: CurrentDealership;
  searchFormValues?: SearchFormValues;
}

export function getDefaultFormValues({
  currentDealership,
  searchFormValues,
}: DefaultFormOptions): SearchFormValues {
  const defaultProgramVariations = currentDealership.programVariations?.default;

  return {
    comparisonCarId: undefined,
    vinOrStockNumber: searchFormValues?.vinOrStockNumber ?? '',
    dealer: searchFormValues?.dealer ?? [],
    programAvailability: searchFormValues?.programAvailability ?? [],
    bodyStyle: searchFormValues?.bodyStyle ?? [],
    exteriorColor: searchFormValues?.exteriorColor ?? [],
    interiorColor: searchFormValues?.interiorColor ?? [],
    make: searchFormValues?.make ?? [],
    mileage: searchFormValues?.mileage ?? { from: undefined, to: undefined },
    model: searchFormValues?.model ?? [],
    carState: searchFormValues?.carState ?? [],
    price: searchFormValues?.price ?? {
      from: undefined,
      to: undefined,
    },
    trim: searchFormValues?.trim ?? [],
    year: searchFormValues?.year ?? {
      from: undefined,
      to: undefined,
    },
    featureTypes: searchFormValues?.featureTypes ?? [],
    fuelType: searchFormValues?.fuelType ?? [],
    monthlyPayment: searchFormValues?.monthlyPayment ?? { from: undefined, to: undefined },
    leaseMonthlyPayment: searchFormValues?.leaseMonthlyPayment ?? {
      from: undefined,
      to: undefined,
    },
    loanMonthlyPayment: searchFormValues?.loanMonthlyPayment ?? {
      from: undefined,
      to: undefined,
    },
    leaseProfit: searchFormValues?.leaseProfit,
    loanProfit: searchFormValues?.loanProfit,
    calculationOptions: {
      creditScore:
        searchFormValues?.calculationOptions?.creditScore ?? currentDealership.creditScore,
      downPayment:
        searchFormValues?.calculationOptions?.downPayment ?? currentDealership.downPayment,
      tradeIn: searchFormValues?.calculationOptions?.tradeIn ?? 0,
      tradeInOutstandingBalance:
        searchFormValues?.calculationOptions?.tradeInOutstandingBalance ?? 0,
      actualCashValue: searchFormValues?.calculationOptions?.actualCashValue ?? 0,
      payAllFeesUpfront:
        searchFormValues?.calculationOptions?.payAllFeesUpfront ??
        currentDealership.payAllFeesUpfront ??
        true,
      maxOutOfPocket: searchFormValues?.calculationOptions?.maxOutOfPocket ?? 0,
      vehiclePrice: searchFormValues?.calculationOptions?.vehiclePrice,
      incentives: searchFormValues?.calculationOptions?.incentives,
      addons: searchFormValues?.calculationOptions?.incentives,
      residual: searchFormValues?.calculationOptions?.residual,
      moneyFactor: searchFormValues?.calculationOptions?.moneyFactor,
      leaseTerms:
        searchFormValues?.calculationOptions?.leaseTerms ?? defaultProgramVariations?.leaseTerms,
      leaseMileages:
        searchFormValues?.calculationOptions?.leaseMileages ??
        defaultProgramVariations?.leaseMileages,
      loanTerms:
        searchFormValues?.calculationOptions?.loanTerms ?? defaultProgramVariations?.loanTerms,
      location:
        // ZipFormField requires location to be not null
        searchFormValues?.calculationOptions?.location ??
        ({
          zipCode: '',
        } as LocationWithZip),
      onlyCaptive: false,
      payFirstLeasePaymentUpfront:
        searchFormValues?.calculationOptions?.payFirstLeasePaymentUpfront ?? true,
      zipCode: searchFormValues?.calculationOptions?.location?.zipCode,
    },
    customer: searchFormValues?.customer ?? createDefaultCustomer(),
    orderBy: searchFormValues?.orderBy ?? 0,
    orderDirection: searchFormValues?.orderDirection ?? 0,
  };
}

export function getSearchFormValues(values: SearchFormValues): SearchFormValues {
  const { price, mileage, year, monthlyPayment, calculationOptions, customer } = values;
  const { from: priceFrom, to: priceTo } = price;
  const { from: mileageFrom, to: mileageTo } = mileage;
  const { from: yearFrom, to: yearTo } = year;
  const { from: monthlyPaymentFrom, to: monthlyPaymentTo } = monthlyPayment;
  const {
    payAllFeesUpfront,
    creditScore,
    downPayment,
    tradeIn,
    tradeInOutstandingBalance,
    actualCashValue,
    maxOutOfPocket,
    location,
    payFirstLeasePaymentUpfront,
    leaseMileages,
    leaseTerms,
    loanTerms,
    zipCode,
  } = calculationOptions;

  const { email, name, phone, id, creditStatus, creditTierId } = customer;

  return {
    ...values,
    price: { from: priceFrom ? +priceFrom : undefined, to: priceTo ? +priceTo : undefined },
    year: { from: yearFrom ? +yearFrom : undefined, to: yearTo ? +yearTo : undefined },
    monthlyPayment: {
      from: monthlyPaymentFrom ? +monthlyPaymentFrom : undefined,
      to: monthlyPaymentTo ? +monthlyPaymentTo : undefined,
    },
    mileage: {
      from: mileageFrom ? +mileageFrom : undefined,
      to: mileageTo ? +mileageTo : undefined,
    },
    calculationOptions: {
      payAllFeesUpfront,
      creditScore: creditScore ? +creditScore : undefined,
      downPayment: downPayment ? +downPayment : undefined,
      leaseMileages,
      leaseTerms,
      loanTerms,
      tradeInOutstandingBalance: tradeInOutstandingBalance ? +tradeInOutstandingBalance : undefined,
      tradeIn: tradeIn ? +tradeIn : undefined,
      actualCashValue: actualCashValue ?? (tradeIn ? +tradeIn : undefined),
      maxOutOfPocket: maxOutOfPocket ? +maxOutOfPocket : undefined,
      location: {
        zipCode: location?.zipCode ?? '',
        city: location?.city ?? '',
        county: location?.county ?? '',
        id: location?.id,
        state: location?.state ?? '',
      },
      onlyCaptive: false,
      payFirstLeasePaymentUpfront,
      zipCode,
    },
    customer: { email, name, phone, id, creditStatus, creditTierId },
    orderBy: values.orderBy,
    orderDirection: values.orderDirection,
  };
}

export function getSearchFiltersPayload({
  comparisonCarId,
  vinOrStockNumber,
  dealer,
  programAvailability,
  bodyStyle,
  make,
  mileage,
  model,
  carState,
  trim,
  year,
  price,
  exteriorColor,
  interiorColor,
  featureTypes,
  fuelType,
  monthlyPayment,
  leaseMonthlyPayment,
  loanMonthlyPayment,
  leaseProfit,
  loanProfit,
  calculationOptions: {
    creditScore,
    downPayment,
    tradeIn,
    tradeInOutstandingBalance,
    actualCashValue,
    payAllFeesUpfront,
    maxOutOfPocket,
    vehiclePrice,
    incentives,
    addons,
    residual,
    moneyFactor,
    leaseTerms,
    leaseMileages,
    loanTerms,
    onlyCaptive,
    payFirstLeasePaymentUpfront,
    zipCode,
  },
  orderBy,
  orderDirection,
}: SearchFormValues): SearchFilterOptions {
  const { from: priceFrom, to: priceTo } = price;
  const { from: mileageFrom, to: mileageTo } = mileage;
  const { from: yearFrom, to: yearTo } = year;
  const { from: monthlyPaymentFrom, to: monthlyPaymentTo } = monthlyPayment;
  const { from: leaseMonthlyPaymentFrom, to: leaseMonthlyPaymentTo } = leaseMonthlyPayment ?? {
    from: undefined,
    to: undefined,
  };
  const { from: loanMonthlyPaymentFrom, to: loanMonthlyPaymentTo } = loanMonthlyPayment ?? {
    from: undefined,
    to: undefined,
  };

  return {
    comparisonCarId,
    vinOrStockNumber,
    dealer,
    programAvailability: programAvailability.map((itemValue) => +itemValue),
    bodyStyle,
    exteriorColor: exteriorColor.map((color) => +color),
    interiorColor: interiorColor.map((color) => +color),
    make,
    mileage: {
      from: mileageFrom ? +mileageFrom : undefined,
      to: mileageTo ? +mileageTo : undefined,
    },
    model,
    carState: carState.map((itemValue) => +itemValue),
    price: { from: priceFrom ? +priceFrom : undefined, to: priceTo ? +priceTo : undefined },
    trim,
    year: { from: yearFrom ? +yearFrom : undefined, to: yearTo ? +yearTo : undefined },
    features: featureTypes.map((featureType) => +featureType),
    fuelType,
    monthlyPayment: {
      from: monthlyPaymentFrom ? +monthlyPaymentFrom : undefined,
      to: monthlyPaymentTo ? +monthlyPaymentTo : undefined,
    },
    leaseMonthlyPayment: {
      from: leaseMonthlyPaymentFrom ? +leaseMonthlyPaymentFrom : undefined,
      to: leaseMonthlyPaymentTo ? +leaseMonthlyPaymentTo : undefined,
    },
    loanMonthlyPayment: {
      from: loanMonthlyPaymentFrom ? +loanMonthlyPaymentFrom : undefined,
      to: loanMonthlyPaymentTo ? +loanMonthlyPaymentTo : undefined,
    },
    leaseProfit,
    loanProfit,
    calculationOptions: {
      payAllFeesUpfront,
      creditScore: creditScore ? +creditScore : undefined,
      downPayment: downPayment ? +downPayment : undefined,
      tradeInOutstandingBalance: tradeInOutstandingBalance ? +tradeInOutstandingBalance : undefined,
      tradeIn: tradeIn ? +tradeIn : undefined,
      actualCashValue: actualCashValue ?? (tradeIn ? +tradeIn : undefined),
      maxOutOfPocket: maxOutOfPocket ? +maxOutOfPocket : undefined,
      vehiclePrice: vehiclePrice ? +vehiclePrice : undefined,
      incentives,
      addons,
      residual,
      moneyFactor,
      location: undefined as LocationWithZip | undefined,
      leaseTerms,
      leaseMileages,
      loanTerms,
      onlyCaptive,
      payFirstLeasePaymentUpfront,
      zipCode: zipCode === '' ? undefined : zipCode,
    },
    orderBy: orderBy !== undefined ? +orderBy : undefined,
    orderDirection: orderDirection !== undefined ? +orderDirection : undefined,
  };
}

export function getNextSkipPageSearchParams(
  { count }: SearchResult,
  allPages: SearchResult[] = []
): number | undefined {
  const sumOfAllPages = _.sum(allPages.map(({ items }) => items.length));

  if (sumOfAllPages >= count) {
    return undefined;
  }

  return sumOfAllPages;
}

export function getCurrentCalculationOptions(
  pdpValues: PdpFormValues,
  searchFormValues: SearchFormValues
): CalculationOptions {
  const {
    creditScore,
    downPayment,
    tradeInOutstandingBal,
    tradeInValue,
    actualCashValue,
    maxOutOfPocket,
    buyer: { location },
    captive,
  } = pdpValues;

  const newCalculationOptions: CalculationOptions = {
    creditScore,
    downPayment,
    // pay all fees up front, lease term / mileage, and loan term filters should not be synced with pdp
    payAllFeesUpfront: searchFormValues.calculationOptions.payAllFeesUpfront,
    leaseMileages: searchFormValues.calculationOptions.leaseMileages,
    leaseTerms: searchFormValues.calculationOptions.leaseTerms,
    loanTerms: searchFormValues.calculationOptions.loanTerms,
    tradeIn: tradeInValue,
    actualCashValue,
    tradeInOutstandingBalance: tradeInOutstandingBal,
    maxOutOfPocket,
    location,
    onlyCaptive: captive,
    payFirstLeasePaymentUpfront: true,
    zipCode: location?.zipCode,
  };

  return newCalculationOptions;
}
